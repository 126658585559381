import { ReactNode } from 'react';
import ReactModal from 'react-modal';
import { ModalProvider } from 'react-modal-hook';

import Footer from 'components/footer/minimal';
import Head from 'components/globals/head';

ReactModal.setAppElement('#___gatsby');

type LayoutMinimalFooterProps = {
  children: ReactNode
} 

const Layout  = ({ children }:LayoutMinimalFooterProps) => {
  return (
    <div className="layout-wrapper">
      <Head />
      <ModalProvider>
        <div role="main">{children}</div>
        <Footer backgroundColor="white" />
      </ModalProvider>
    </div>
  );
};

export default Layout;
