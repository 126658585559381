import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/minimal-footer-only';

import HighlightCards from 'components/content-blocks/highlight';
import Yoast from 'components/globals/yoast';
import { HeroSubscription } from 'components/hero/subscription';

import './styles/subscription.scss';

export default function BlogSubscription() {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "subscription-blog" } }) {
        edges {
          node {
            wordpress_id
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
            acf {
              hero {
                title
                sub_title
                vero {
                  button_text
                }
              }
              cards_1 {
                header {
                  title
                  sub_title
                  copy
                }
                card {
                  header
                  image {
                    source_url
                  }
                  title
                  copy
                  link_url
                  link_text
                }
              }
            }
          }
        }
      }
    }
  `);

  const pageContent = data.allWordpressPage.edges[0].node.acf;
  const yoast = data.allWordpressPage.edges[0].node.yoast_head_json;
  const hero = pageContent.hero;
  const cards = pageContent.cards_1;

  return (
    <Layout>
      <Yoast { ...yoast } />
      <div className="subscription-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroSubscription formType="blogSubscription" emailField { ...hero } />
        <HighlightCards { ...cards } hasTitle hasImage />
      </div>
    </Layout>
  );
}
