import { graphql, useStaticQuery } from 'gatsby';

import StructuredData from 'components/content-blocks/partials/structured-data';
import Link from 'components/link/link';

import './styles/minimal.scss';

type Props = {
  backgroundColor?: string
}

const Footer = (props:Props) => {

  const data = useStaticQuery(graphql`
    {
      allWordpressAcfOptions {
        edges {
          node {
            options {
              structured_data {
                default_site_data
              }
            }
          }
        }
      }
    }
  `);

  const pageContext = data.allWordpressAcfOptions.edges[0].node;
  const structuredData = pageContext.options.structured_data.default_site_data;

  return (
    <footer className="minimal-footer">
      <section
        className="footer-section"
        style={ {
          backgroundColor: `${
            props.backgroundColor ? props.backgroundColor : '#ecf9e8'
          }`,
        } }
      >
        <div className="container-large">
          <section
            className="footer-wrap bottom"
            data-section="footer-legal-links"
          >
            <div className="footer-copyright">
              <p className="footer-text">
                &copy; {new Date().getFullYear()} When I Work, Inc.
              </p>
              <ul className="footer-legal">
                <li>
                  <span className="separator">&nbsp;|&nbsp;</span>
                  <Link
                    to="/privacy"
                    className="legal-link"
                    title="Privacy Policy"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <span className="separator">&nbsp;|&nbsp;</span>
                  <Link
                    to="/terms"
                    className="legal-link"
                    title="Terms of Service"
                  >
                    Terms of Service
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-logo">
              <img
                loading="lazy"
                src="https://marketing-assets.wheniwork-production.com/2020/05/14134746/footer-wheniwork-logo.svg"
                alt="When I Work - Get Shift Done - Logo"
              />
            </div>
          </section>
        </div>
      </section>
      <StructuredData data={ structuredData } />
    </footer>
  );
};

export default Footer;
