import Vero from 'components/forms/vero';
import Link from 'components/link/link';

import './styles/subscription.scss';

type HeroSubscriptionProps = {
  formType: string
  sub_title: string
  title: string
  vero: ButtonText
}

type ButtonText = {
  button_text: string
}

export const HeroSubscription = ({ title, sub_title, vero, formType }:HeroSubscriptionProps) => (
  <section className="section subscription-hero-section">
    <div className="container">
      <Link to="/">
        <img
          className="logo"
          src="https://marketing-assets.wheniwork-production.com/2019/08/15163825/logo.svg"
          alt="When I Work Logo - To Home Page"
          width="167"
          height="31"
        />
      </Link>

      <div className="columns is-5">
        <div className="column is-two-thirds marketing-wrapper">
          <h1 className="title">{title}</h1>
          <p className="lead">{sub_title}</p>
        </div>
        <div className="column is-one-thirds signup-wrapper">
          <Vero
            formType={ formType }
            emailField
            uniqueId="veroSubscribe"
            fieldLayout="row"
            submitBtnText={ vero.button_text }
          />
        </div>
      </div>
    </div>
  </section>
);